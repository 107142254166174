import React, { useContext } from 'react'
import Hero from './components/Hero'
import Download from '../Events/components/Download'
import ScrollContainer from '../../baseComponents/ScrollContainer'

import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'

import './index.css'
import Footer from '../../baseComponents/Footer'

const Downloads = () => {
  const context = useContext(SettingsContext)
    , { lang } = context
  return <ScrollContainer>
    <div id='downloads'>
      <Hero lang={lang} getLocal={getLocal} />
      <Download lang={lang} getLocal={getLocal} />
      <Footer />
    </div>
  </ScrollContainer>
}

export default Downloads