import React, { useRef, useEffect, useState } from 'react'

import './index.css'

const Embed = props => {
  const [className, setClassName] = useState('active')
  let intervalRef = useRef(null)
    , containerRef = useRef(null)

  const onPlayPause = () => {
    if (containerRef.current) {
      const iframe = containerRef.current.querySelector('iframe')
      var player = new window.Vimeo.Player(iframe)
      player.on('play', function () {
        player.setVolume(0.2)
      });
      player.getPaused().then(function (paused) {
        paused ? player.play() : player.pause()
      })
    }
  }

  useEffect(() => {
    if (className !== 'active')
      intervalRef.current = setInterval(() => {
        setClassName('active')
      }, 1000)

    return () => { clearInterval(intervalRef.current) }
  }, [className])

  return <div
    ref={containerRef}
    className={`embed__container ${className}`}
    onMouseMove={() => setClassName('')}
    onClick={() => {
      setClassName('')
      onPlayPause()
    }}
  >
    {props.children}
  </div>
}

export default Embed