import lottieCircle1 from '../../assets/lotties/circle_01.json'
import lottieCircle2 from '../../assets/lotties/circle_02.json'
import lottieCircle3 from '../../assets/lotties/circle_03.json'
import lottieCircle4 from '../../assets/lotties/circle_04.json'

import lottieDot1 from '../../assets/lotties/dot_01.json'
import lottieDot2 from '../../assets/lotties/dot_02.json'
import lottieDot3 from '../../assets/lotties/dot_03.json'
import lottieDot4 from '../../assets/lotties/dot_04.json'
import lottieDot5 from '../../assets/lotties/dot_05.json'

import lottieLine1 from '../../assets/lotties/line_01.json'
import lottieLine2 from '../../assets/lotties/line_02.json'
import lottieLine3 from '../../assets/lotties/line_03.json'


export const types = {
  circles: ['lottieCircle1', 'lottieCircle2', 'lottieCircle3'],
  dots: ['lottieDot1', 'lottieDot2', 'lottieDot3', 'lottieDot4', 'lottieDot5'],
  lines: ['lottieLine1', 'lottieLine2', 'lottieLine3']
}


const lotties = {
  lottieCircle1,
  lottieCircle2,
  lottieCircle3,
  lottieCircle4,
  lottieDot1,
  lottieDot2,
  lottieDot3,
  lottieDot4,
  lottieDot5,
  lottieLine1,
  lottieLine2,
  lottieLine3,
}

export default lotties