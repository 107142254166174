const locals = {
  'about': { 'es': 'Acerca', 'en': 'About' },
  'home': { 'es': 'Inicio', 'en': 'Home' },
  'contact_us': { 'es': 'Contáctanos', 'en': 'Contact us' }
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
