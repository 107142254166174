import React, { useContext } from 'react'
import Hero from './components/Hero'
import Events from './components/Events'
import VideoBanner from './components/VideoBanner'
import Stream from './components/Stream'
import ScrollContainer from '../../baseComponents/ScrollContainer'

import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'

import './index.css'

const Home = props => {
  const context = useContext(SettingsContext)
    , { lang } = context

  return <ScrollContainer>
    <div id='home' data-scroll-section>
      <Hero lang={lang} getLocal={getLocal} />
      <Events lang={lang} getLocal={getLocal} />
      <VideoBanner />
      <Stream lang={lang} getLocal={getLocal} />
    </div>
  </ScrollContainer>
}

export default Home