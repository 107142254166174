import React, { useEffect, useState } from 'react'
import Footer from '../../../baseComponents/Footer'
import EventCard from './EventCard'
import Slider from 'react-slick'
import AnimatedBG from '../../../baseComponents/AnimatedBG'

import { fbDatabase } from '../../../services/fb'

import avatarLeft from '../../../assets/images/avatars/avatar_01.png'
import avatarRight from '../../../assets/images/avatars/avatar_03.png'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import EventForm from './EventForm'

const SlideBtn = ({ className, style, onClick }) => {
  return <div
    className={`slide-btn ${className}`}
    style={{ ...style, }}
    onClick={onClick}
  />
}

const SliderEvents = ({ events }) => {

  const settings = {
    infinite: events.length > 2,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SlideBtn />,
    prevArrow: <SlideBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return <Slider {...settings}>
    {
      events.map((eventInfo, index) => <EventCard key={index} event={eventInfo} />)
    }
  </Slider>
}


const News = props => {
  const { getLocal, lang } = props

  const [events, setEvents] = useState([])
    , [showNewEvents, setShowNewEvents] = useState(true)
    , [filteredEvents, setFilteredEvents] = useState([])

  const onFilterEventHandler = (events, eventsFilter) => {
    events = events
      .map(evt => ({ ...evt, now: (new Date(evt.EndDate)).getTime() }))
      .sort((eventA, eventB) => eventA.SortIndex - eventB.SortIndex)
    setFilteredEvents(events)
  }

  const onClickHandler = active => {
    setShowNewEvents(active)
    onFilterEventHandler(events, active)
  }

  useEffect(() => {
    const eventsRef = fbDatabase.ref('EVENTS').orderByChild('IsReady').equalTo(true)
    eventsRef.on('value', snapshot => {
      let eventSnapshot = snapshot.val()
        , eventList = []

      for (let id in eventSnapshot)
        eventList.push({ id, ...eventSnapshot[id] })

      onFilterEventHandler(eventList)
      setEvents(eventList)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className='events__news'>
    <AnimatedBG>
      <div className='site__block events__news_title' >
        <h2 className="font_bold">{getLocal('event_title', lang)}</h2>
        <p>{getLocal('event_paragraph', lang)}</p>
      </div>
    </AnimatedBG>
    <EventForm lang={lang} getLocal={getLocal} />
    <AnimatedBG>
      <div className='events__news_slider'>
        <div className='buttons-container'>
          <span isActive={showNewEvents} onClick={() => onClickHandler(true)}>
            <span className="font_bold">{getLocal('event_slider_title', lang)}</span>
          </span>
          {/*           <span isActive={!showNewEvents} onClick={() => onClickHandler(false)}>
            <span>Eventos pasados</span>
          </span> */}
        </div>
        <div className='slider-container'>
          <SliderEvents events={filteredEvents} />
        </div>
        <img className='events__news_avatar left' src={avatarLeft} alt='Clementine' />
        <img className='events__news_avatar right' src={avatarRight} alt='Lucho' />
      </div>
    </AnimatedBG>
    <Footer />
  </div>
}

export default News