import image1 from '../../../assets/images/about/hoppia_about_01.png'
import image2 from '../../../assets/images/about/hoppia_about_02.png'
import AnimatedBG from '../../../baseComponents/AnimatedBG'

const Experience = props => {
  const { getLocal, lang } = props

  return <AnimatedBG>
    <div className='about__experience'>
      <img className='image_one' src={image1} alt='hoppia 1' data-scroll data-scroll-speed='2' />
      <h3 className="font_bold">
        {getLocal('experience_paragraph', lang)}
      </h3>
      <img className='image_two' data-scroll data-scroll-speed='1'
        data-scroll-delay='0.40' src={image2} alt='hoppia 1' />
    </div>
  </AnimatedBG>
}

export default Experience
