import React, { useContext } from 'react'
import Hero from './components/Hero'
import Products from './components/Products'
import ScrollContainer from '../../baseComponents/ScrollContainer'
import VideoBanner from './components/VideoBanner'
import Experience from './components/Experience'
import Users from './components/Users'
import News from './components/News'

import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'

import './index.css'

const About = props => {
  const context = useContext(SettingsContext)
    , { lang } = context

  return <ScrollContainer>
    <div className='about' data-scroll-section>
      <Hero lang={lang} getLocal={getLocal} />
      <Products lang={lang} getLocal={getLocal} />
      <VideoBanner />
      <Experience lang={lang} getLocal={getLocal} />
      <Users lang={lang} getLocal={getLocal} />
      <News lang={lang} getLocal={getLocal} />
    </div>
  </ScrollContainer>
}

export default About