const locals = {
  'required_field': {
    'es': '* Campo obligatorio',
    'en': '* Required field'
  },
  'invalid_email': {
    'es': '* Correo electrónico no valido',
    'en': '* invalid_email'
  },
  'invalid_phone': {
    'es': '* No parece ser un número de teléfono',
    'en': '* invalid_phone'
  },
  'negative_attendants': {
    'es': '* El número de asistentes no puede ser negativo',
    'en': '* negative_attendants'
  },
  'zero_attendants': {
    'es': '* El número de asistentes no puede ser cero',
    'en': '* zero_attendants'
  }
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
