const locals = {
  'hero_subtitle': {
    'es': 'Eventos virtuales con avatares, interacciones en vivo y juegos',
    'en': 'hero_subtitle'
  },
  'info_title': {
    'es': 'Conciertos, festivales, exposiciones, conferencias, ferias, seminarios y más.',
    'en': 'info_title'
  },
  'download_title': {
    'es': 'Prueba Hoppia Events ahora',
    'en': 'download_title'
  },
  'download_btn': {
    'es': 'Descargar',
    'en': 'Download'
  },
  'event_title': {
    'es': '¿Quieres organizar tu evento en hoppia?',
    'en': 'event_title'
  },
  'event_paragraph': {
    'es': 'Tu evento virtual como tu lo quieras; Múltiples escenarios salas VIP, avatares personalizados, chat, minijuegos y gamification',
    'en': 'event_paragraph'
  },
  'event_slider_title': {
    'es': 'Eventos',
    'en': 'event_slider_title'
  },
  'form_name_label': {
    'es': 'Nombre',
    'en': 'Name'
  },
  'form_email_label': {
    'es': 'Email',
    'en': 'Email'
  },
  'form_phone_label': {
    'es': 'Teléfono',
    'en': 'Phone'
  },
  'form_attendants_label': {
    'es': 'Número de asistentes:',
    'en': 'form_attendants_label'
  },
  'form_event_date_label': {
    'es': 'Fecha de tu evento',
    'en': 'form_event_date_label'
  },
  'form_comments_label': {
    'es': 'Comentarios',
    'en': 'form_comments_label'
  },
  'form_btn_send': {
    'es': 'Enviar',
    'en': 'Send'
  },
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
