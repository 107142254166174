import React, { useContext } from 'react'
import ScrollContainer from '../../baseComponents/ScrollContainer'
import Content from './conponents/Content'
import Hero from './conponents/Hero'

import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'

import './index.css'

const Contact = props => {
  const context = useContext(SettingsContext)
    , { lang } = context

  return <ScrollContainer>
    <div className='contact' data-scroll-section>
      <Hero lang={lang} getLocal={getLocal} />
      <Content lang={lang} getLocal={getLocal} />
    </div>
  </ScrollContainer>
}

export default Contact