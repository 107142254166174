import Footer from '../../../baseComponents/Footer'
import InfoForm from '../../../baseComponents/InfoForm'

import bg from '../../../assets/images/home/home_bubble_bg.png'

const Stream = props => {
  const { getLocal, lang } = props

  return <div className='home__stream'>
    <div className='site__block home__stream_container'>
      <div className='home__stream_title'
        data-scroll
        data-scroll-direction='vertical'
        data-scroll-speed='1'>
        <h2 className="font_bold">{getLocal('stream_question_part_one', lang)} <br className="hidden-mobile" />
          {getLocal('stream_question_part_two', lang)} <br className="hidden-mobile" />
          {getLocal('stream_question_part_three', lang)} <br className="hidden-mobile" />
          {getLocal('stream_question_part_four', lang)}</h2>
      </div>

      <InfoForm />

      <img className='home__stream_background' src={bg} alt='Fondo gris' />
    </div>
    <Footer />
  </div>
}

export default Stream
