import React, { useEffect, useState } from 'react'
import NewsSlider from './NewsSlider'
import Footer from '../../../baseComponents/Footer'
import InfoForm from '../../../baseComponents/InfoForm'
import AnimatedBG from '../../../baseComponents/AnimatedBG'
import { fbDatabase } from '../../../services/fb'

import bg from '../../../assets/images/home/home_bubble_bg.png'
import shape from '../../../assets/images/about/hoppia_about_03.png'
import avatarLeft from '../../../assets/images/avatars/avatar_04.png'
import avatarRight from '../../../assets/images/avatars/avatar_03.png'

const News = props => {
  const { getLocal, lang } = props
  const [news, setNews] = useState([])

  useEffect(() => {
    const eventsRef = fbDatabase.ref('SITE_NEWS')
    eventsRef.on('value', snapshot => {
      let eventSnapshot = snapshot.val()
        , eventList = []

      for (let id in eventSnapshot)
        eventList.push({ id, ...eventSnapshot[id] })

      setNews(eventList)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className='about__news'>
    <AnimatedBG>
      <div className='about__news_container site__block'>
        <h4 className="font_bold news_title">{getLocal('news_title', lang)}</h4>
        <NewsSlider news={news} />
        <img className='about__shape' src={shape} alt='Hoppia' />
      </div>
    </AnimatedBG>
    <div className='home__stream'>
      <div className='site__block home__stream_container'>
        <div className='home__stream_title'
          data-scroll
          data-scroll-direction='horizontal'
          data-scroll-speed='-1'>
          <h2 className="font_bold">{getLocal('stream_question_part_one', lang)} <br className="hidden-mobile" />
            {getLocal('stream_question_part_two', lang)} <br className="hidden-mobile" />
            {getLocal('stream_question_part_three', lang)} <br className="hidden-mobile" />
            {getLocal('stream_question_part_four', lang)}</h2>
        </div>

        <InfoForm />

        <img className='home__stream_background' src={bg} alt='Fondo gris' />
      </div>
      <div className='events__news_container'>
        <img className='events__news_avatar left' src={avatarLeft} alt='Clementine' />
        <img className='events__news_avatar right' src={avatarRight} alt='Lucho' />
      </div>
      <Footer />
    </div>
  </div>
}

export default News