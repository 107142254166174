import Header from '../../../baseComponents/Header'

import hoppiaLogo from '../../../assets/svg/hoppia_events_logo.svg'

const Hero = props => {
  const { getLocal, lang } = props

  return <div className='events__hero'>
    <Header />
    <div className='site__block home__hero_logo'>
      <img className="hero_image_title" src={hoppiaLogo} alt='hoppia' />
      <h4 className="hero_title text-align-center">{getLocal('hero_subtitle', lang)}</h4>
    </div>
    <div className='home__bottom_shape' />
  </div>
}

export default Hero