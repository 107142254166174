import React from 'react'
import Footer from '../../../baseComponents/Footer'
import AnimatedBG from '../../../baseComponents/AnimatedBG'
import InfoForm from '../../../baseComponents/InfoForm'
import { Link } from 'react-router-dom'

import productTablet from '../../../assets/images/about/about_tablet.png'
import vtuberBlue from '../../../assets/svg/hoppia_vtuber_blue.svg'
import eventsPurple from '../../../assets/svg/hoppia_events_purple.svg'
import { getUrl } from '../../../utils/routes'
import avatarLeft from '../../../assets/images/avatars/avatar_04.png'
import avatarRight from '../../../assets/images/avatars/avatar_03.png'

import bg from '../../../assets/images/contact/contact_bubble_bg.png'

const Content = props => {
  const { getLocal, lang } = props

  return <div className='contact__content'>
    <img
      data-scroll data-scroll-speed='1'
      data-scroll-delay='0.40'
      src={productTablet} alt='tableta' className='about__products_tablet' />
    <AnimatedBG>
      <div className='home__stream'>
        <div className='site__block home__stream_container'>
          <div className='home__stream_title'
            data-scroll
            data-scroll-direction='horizontal'
            data-scroll-speed='-1'>
            <h2 className="font_bold">{getLocal('stream_question_part_one', lang)} <br className="hidden-mobile" />
              {getLocal('stream_question_part_two', lang)} <br className="hidden-mobile" />
              {getLocal('stream_question_part_three', lang)} <br className="hidden-mobile" />
              {getLocal('stream_question_part_four', lang)}
            </h2>
          </div>

          <InfoForm />

          <img className='home__stream_background' src={bg} alt='Fondo gris' />
        </div>
      </div>
    </AnimatedBG>
    <div className='about__products site__block'>
      <div className='about__products_title' data-scroll data-scroll-speed='2' data-scroll-delay='0.20'>
        <h2 className='text-align-center font_bold'>{getLocal('products_title', lang)}</h2>
      </div>
      <div className='about__products_cards' >
        <div className='card__container' data-scroll data-scroll-speed='2' data-scroll-delay='0.20'>
          <img className='card__container_image' src={vtuberBlue} alt='Vtuber' />
          <h4>{getLocal('products_vtuber_title', lang)}</h4>
          <ul>
            <li>{getLocal('products_vtuber_desc', lang)}</li>
          </ul>
          <Link to={getUrl('vtuber')} className='btn'>{getLocal('products_btn', lang)}</Link>
        </div>

        <div className='card__container' data-scroll data-scroll-speed='2' data-scroll-delay='0.80'>
          <img className='card__container_image' src={eventsPurple} alt='Eventos' />
          <h4>{getLocal('products_events_title', lang)}</h4>
          <ul>
            <li>{getLocal('products_events_desc', lang)}</li>
          </ul>
          <Link to={getUrl('vtuber')} className='btn bg_purple'>{getLocal('products_btn', lang)}</Link>
        </div>
      </div>
    </div>
    <div className='events__news_container'>
      <img className='events__news_avatar left' src={avatarLeft} alt='Clementine' />
      <img className='events__news_avatar right' src={avatarRight} alt='Lucho' />
    </div>
    <Footer />
  </div>
}

export default Content