import { Link } from 'react-router-dom'

import productTablet from '../../../assets/images/about/about_tablet.png'
import vtuberBlue from '../../../assets/svg/hoppia_vtuber_blue.svg'
import eventsPurple from '../../../assets/svg/hoppia_events_purple.svg'
import { getUrl } from '../../../utils/routes'

const Products = props => {
  const { getLocal, lang } = props

  return <div className='about__products site__block'>
    <img
      data-scroll data-scroll-speed='1'
      data-scroll-delay='0.40'
      src={productTablet} alt='tableta' className='about__products_tablet' />
    <div className='about__products_title' data-scroll data-scroll-speed='2' data-scroll-delay='0.20'>
      <h2 className='text-align-center font_bold'>{getLocal('products_title', lang)}</h2>
    </div>
    <div className='about__products_cards' >
      <div className='card__container' data-scroll data-scroll-speed='2' data-scroll-delay='0.20'>
        <img className='card__container_image' src={vtuberBlue} alt='Vtuber' />
        <h4>{getLocal('products_vtuber_title', lang)}</h4>
        <ul>
          <li>{getLocal('products_vtuber_desc', lang)}</li>
        </ul>
        <Link to={getUrl('vtuber')} className='btn'>{getLocal('products_btn', lang)}</Link>
      </div>

      <div className='card__container' data-scroll data-scroll-speed='2' data-scroll-delay='0.80'>
        <img className='card__container_image' src={eventsPurple} alt='Eventos' />
        <h4>{getLocal('products_events_title', lang)}</h4>
        <ul>
          <li>{getLocal('products_events_desc', lang)}</li>
        </ul>
        <Link to={getUrl('vtuber')} className='btn bg_purple'>{getLocal('products_btn', lang)}</Link>
      </div>
    </div>
  </div>
}

export default Products