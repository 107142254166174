import Header from '../../../baseComponents/Header'

const Hero = props => {
  const { getLocal, lang } = props

  return <div className='about__hero'>
    <Header />
    <div className='site__block home__hero_logo'>
      <h1 className='home__hero_title'>{getLocal('title', lang)}</h1>
      <p className="hero_title text-align-center" dangerouslySetInnerHTML={{ __html: getLocal('hero_subtitle', lang) }} />
    </div>
    <div className='home__bottom_shape' />
  </div>
}

export default Hero