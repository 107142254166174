const routes = {
  about: { url: '/acerca' },
  events: { url: '/eventos' },
  contact: { url: '/contacto' },
  download: { url: '/events/descarga' },
  home: { url: '/' },
  vtuber: { url: '/vTuber' },
  legals: { url: '/terminos_y_condiciones' },
  privacy: { url: '/aviso_de_privacidad' },
}

export const getUrl = (name) => (routes[name] ? routes[name].url : '/')
