import React, { useEffect, useRef } from 'react'
import './index.css'
const bodymovin = window.bodymovin

const Lottie = props => {
  const { animation, onComplete = () => null } = props
  const lottieRef = useRef(null)

  useEffect(() => {

    let lottieAnimation = bodymovin.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation
    })
    const onDataReady = () => {
      lottieAnimation.play()
    }
    const onCompleteAnimation = () => {
      lottieAnimation.stop()
      if (!lottieRef.current) return
      if (typeof onComplete === 'function') onComplete()
    }

    lottieAnimation.play()
    lottieAnimation.addEventListener('data_ready', onDataReady)
    lottieAnimation.addEventListener('complete', onCompleteAnimation)

    return () => {
      lottieAnimation.removeEventListener('data_ready', onDataReady)
      lottieAnimation.removeEventListener('complete', onDataReady)
    }
  }, [animation, onComplete])

  const elementProps = Object.assign({}, props)
  delete elementProps.onComplete
  return <div ref={lottieRef} {...elementProps} className={`lottie_item ${props.className}`} />
}


export default Lottie