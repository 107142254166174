import React, { useContext } from 'react'
import Hero from './components/Hero'
import ScrollContainer from '../../baseComponents/ScrollContainer'

import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'

import './index.css'

const Privacy = () => {
  const context = useContext(SettingsContext)
    , { lang } = context

  return <ScrollContainer>
    <div className='privacy' data-scroll-section>
      <Hero lang={lang} getLocal={getLocal} />
      <div className='site__block data__content' dangerouslySetInnerHTML={{ __html: getLocal('page', lang) }} />
    </div>
  </ScrollContainer>
}

export default Privacy