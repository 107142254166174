import React, { useState } from 'react'
import axios from 'axios'
import { validateEventForm } from '../../../utils/inputValidations'
import Loader from '../../../baseComponents/Loader'

import shapeLeft from '../../../assets/images/events/hoppia_events_01.png'
import shape from '../../../assets/images/events/hoppia_events_02.png'

const EventForm = props => {
  const { getLocal, lang } = props

  const [name, setName] = useState('')
    , [email, setEmail] = useState('')
    , [phone, setPhone] = useState('')
    , [attendants, setAttendants] = useState('1')
    , [eventDate, setEventDate] = useState('')
    , [comments, setComments] = useState('')
    , [errors, setErrors] = useState({})
    , [isSending, setIsSending] = useState(false)
    , [success, setSuccess] = useState(false)

  const onSendForm = () => {
    setErrors({})
    let warnings = validateEventForm(name, email, phone, attendants, eventDate)
    if (warnings) return setErrors(warnings)
    setIsSending(true)
    axios({
      url: 'https://hoppia.herokuapp.com/hoppia_event_info',
      method: 'POST',
      data: { email, phone, attendants, comments, eventDate, name }
    })
      .then(res => {
        if (res.data.error) setErrors(res.data.error)
        if (res.data.success) setSuccess(true)
        setIsSending(false)
        setSuccess(true)
      })
      .catch(err => {
        setIsSending(false)
        setErrors({ general: '* algo salió mal.' })
      })
  }

  return <div className='events__news_event_container'>
    <img data-scroll data-scroll-speed='5' data-scroll-delay='0.40' className='events__news_shape_left' src={shapeLeft} alt='Hoppia' />
    <div className='site__block  events__news_event_form'>
      <div className='home__stream_input'>
        <label>{getLocal('form_name_label', lang)} <span className='error'>{errors.name}</span></label>
        <input
          onChange={e => setName(e.target.value)}
          value={name}
          className='text_input' />
      </div>
      <div className='home__stream_input'>
        <label>{getLocal('form_email_label', lang)} <span className='error'>{errors.email}</span></label>
        <input
          type='email'
          onChange={e => setEmail(e.target.value)}
          value={email}
          className='text_input' />
      </div>
      <div className='home__stream_input'>
        <label>{getLocal('form_phone_label', lang)} <span className='error'>{errors.phone}</span></label>
        <input
          type='tel'
          onChange={e => setPhone(e.target.value)}
          value={phone}
          className='text_input' />
      </div>
      <div className='home__stream_input'>
        <label>{getLocal('form_attendants_label', lang)} <span className='error'>{errors.attendants}</span></label>
        <input
          onChange={e => setAttendants(e.target.value)}
          value={attendants}
          type='number'
          className='text_input' />
      </div>
      <div className='home__stream_input'>
        <label>{getLocal('form_event_date_label', lang)} <span className='error'>{errors.eventDate}</span></label>
        <input
          onChange={e => setEventDate(e.target.value)}
          value={eventDate}
          type='date'
          className='text_input' />
      </div>
      <div className='home__stream_input'>
        <label>{getLocal('form_comments_label', lang)}</label>
        <textarea
          rows={10}
          onChange={e => setComments(e.target.value)}
          value={comments}
          className='text_input' />
      </div>
      {
        success ?
          <React.Fragment>
            <div className='site__block events__news_title'>
              <span>Gracias por contactarnos, </span>
              <span>pronto nos comunicaremos contigo.</span>
            </div>
          </React.Fragment>
          : isSending
            ? <span className='btn'><Loader type='circle' /></span>
            : <span onClick={onSendForm} className='btn'>{getLocal('form_btn_send', lang)}</span>
      }
    </div>
    <img data-scroll data-scroll-speed='2' data-scroll-delay='0.40' className='events__news_shape' src={shape} alt='Hoppia' />
  </div>
}

export default EventForm