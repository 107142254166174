import React, { useContext } from 'react'
import Hero from './components/Hero'
import VideoInfo from './components/VideoInfo'
import ScrollContainer from '../../baseComponents/ScrollContainer'
import Info from './components/Info'
import News from './components/News'
import VideoBanner from './components/VideoBanner'
import Download from './components/Download'

import { SettingsContext } from '../../providers/Settings'
import { getLocal } from './locals'

import './index.css'

const Vtuber = props => {
  const context = useContext(SettingsContext)
    , { lang } = context
  return <ScrollContainer>
    <div className='events' data-scroll-section>
      <Hero lang={lang} getLocal={getLocal} />
      <VideoInfo lang={lang} getLocal={getLocal} />
      <Info lang={lang} getLocal={getLocal} />
      <VideoBanner lang={lang} getLocal={getLocal} />
      <Download lang={lang} getLocal={getLocal} />
      <News lang={lang} getLocal={getLocal} />
    </div>
  </ScrollContainer>
}

export default Vtuber