import React, { useState, useEffect } from 'react'
import { fbDatabase } from '../../../services/fb'

import androidIcon from '../../../assets/images/events/iconandroid.png'
import iosIcon from '../../../assets/images/events/iconios.png'
import appleIcon from '../../../assets/images/events/iconapple.png'
import windowsIcon from '../../../assets/images/events/iconwindow.png'

const Download = props => {
  const { getLocal, lang } = props

  let [links, setLinks] = useState({
    AndroidURL: '',
    AndroidVersion: "0.0.1",
    IOSURL: "",
    IOSVersion: "0.0.0",
    MacOsURL: "",
    MacOsVersion: "1.8.7",
    WindowsURL: "",
    WindowsVersion: "1.8.7"
  })

  useEffect(() => {
    const linksRef = fbDatabase.ref('APPSETTINGS')
    let timeout = null
    linksRef.on('value', snapshot => {
      let linksSnapshot = snapshot.val()
      setLinks(linksSnapshot)
    })

    return () => {
      clearTimeout(timeout)
    }
  }, [])


  return <div className='text-align-center site__block events__download'>
    <h2 className="font_bold" data-scroll data-scroll-speed='2'>{getLocal('download_title', lang)}</h2>
    <div className='platform-container' data-scroll data-scroll-speed='2'>
      <div className='platform-item'>
        <img src={androidIcon} alt='android' />
        <div className='button'>
          <a href={links.AndroidURL} target='_blank' rel='noopener noreferrer' className='btn'>{getLocal('download_btn', lang)}</a>
        </div>
      </div>

      <div className='platform-item'>
        <img src={iosIcon} alt='ios' />
        <div className='button'>
          <a href={links.IOSURL} target='_blank' rel='noopener noreferrer' className='btn'>{getLocal('download_btn', lang)}</a>
        </div>
      </div>

      <div className='platform-item'>
        <img src={appleIcon} alt='apple store' />
        <div className='button'>
          <a href={links.MacOsURL} target='_blank' rel='noopener noreferrer' className='btn'>{getLocal('download_btn', lang)}</a>
        </div>
      </div>

      <div className='platform-item'>
        <img src={windowsIcon} alt='windows' />
        <div className='button'>
          <a href={links.WindowsURL} target='_blank' rel='noopener noreferrer' className='btn'>{getLocal('download_btn', lang)}</a>
        </div>
      </div>
    </div>
  </div>
}

export default Download