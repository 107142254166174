import { getLocal } from './inputValidationsLocals'

export const validateEmail = (email = '', lang = 'es') => {
  //eslint-disable-next-line
  let emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!email.trim().length) return { email: getLocal('required_field', lang) }
  if (!emailRegex.test(email))
    return { email: getLocal('invalid_email', lang) }
  return null
}

export const validateUsername = (username = '', lang = 'es') => {
  console.log(username, lang)
  if (!username.trim().length) return { name: getLocal('required_field', lang) }
  return null
}

export const validatePhone = (phone = '', lang = 'es') => {
  //eslint-disable-next-line
  let phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  if (!phone.trim().length) return { phone: getLocal('required_field', lang) }
  if (!phoneRegex.test(phone)) return { phone: getLocal('invalid_phone', lang) }
  return null
}

export const validateAttendants = (attendants = 0, lang = 'es') => {
  const number = parseInt(attendants)
  if (number < 0) return { attendants: getLocal('negative_attendants', lang) }
  if (number === 0) return { attendants: getLocal('zero_attendants', lang) }
  return null
}

export const validateEventDate = (eventDate = '', lang = 'es') => {
  if (!eventDate.trim().length) return { eventDate: getLocal('required_field', lang) }
  //const date = new Date(eventDate)
  return null
}

export const validateInfoForm = ({ name = '', email = '', phone = '', lang = 'es' }) => {
  let warnings = []
  warnings.push(validateUsername(name, lang))
  warnings.push(validateEmail(email, lang))
  warnings.push(validatePhone(phone, lang))

  warnings = warnings.filter(warning => Boolean(warning))
  let warningMessages = {}

  for (let warning of warnings)
    warningMessages = { ...warningMessages, ...warning }

  return warnings.length ? warningMessages : null
}


export const validateEventForm = (
  name = '', email = '', phone = '', attendants = 0, eventDate = ''
) => {
  let warnings = []
  warnings.push(validateUsername(name))
  warnings.push(validateEmail(email))
  warnings.push(validatePhone(phone))
  warnings.push(validateAttendants(attendants))
  warnings.push(validateEventDate(eventDate))

  warnings = warnings.filter(warning => Boolean(warning))
  let warningMessages = {}

  for (let warning of warnings)
    warningMessages = { ...warningMessages, ...warning }

  return warnings.length ? warningMessages : null
}