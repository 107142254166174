import AnimatedBG from "../../../baseComponents/AnimatedBG"

const Info = props => {
  const { getLocal, lang } = props

  return <AnimatedBG>
    <div className='site__block events__video_info_text'>
      <h4 data-scroll data-scroll-speed='1'
        data-scroll-delay='0.40'
        className="font_bold">
        {getLocal('info_title', lang)}
      </h4>
    </div>
  </AnimatedBG>
}

export default Info