import Header from '../../../baseComponents/Header'

import hoppiaLogo from '../../../assets/svg/hoppia_events_logo.svg'

const Hero = props => {
  return <div className='events__hero'>
    <Header />
    <div className='site__block home__hero_logo'>
      <img className="hero_image_title" src={hoppiaLogo} alt='hoppia' />
    </div>
    <div className='home__bottom_shape' />
  </div>
}

export default Hero