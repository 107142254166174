import './index.css'

const Loader = props => {
  //  const { type = 'normal' } = props

  //if (type === 'circle') 
  return <div className='lds-dual-ring' />

  /* return <div>
    <div className='lds-grid'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div> */
}

export default Loader