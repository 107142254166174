import RandomLottie from '../RadomLottie'

import './index.css'

const AnimatedBG = props => {
  const { amount = 4, bgClass = '' } = props

  let elements = new Array(amount).fill(0).map((_, i) => i)

  return <div className={`animated__container ${bgClass}`}>
    <div className="animated__items_container">
      {
        elements.map(index => <RandomLottie key={index} index={index} />)
      }
    </div>
    <div className='animated__block_container'>
      {props.children}
    </div>
  </div>
}

export default AnimatedBG
