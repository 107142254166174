import React, { useEffect, useState } from 'react'
import Lottie from '../Lottie'
import animationJSON, { types } from './animations'

const values = {
  0: types.circles[1],
  1: types.circles[2],
  2: types.dots[1],
  3: types.dots[2],
  4: types.lines[2],
  5: types.lines[2],
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

const RandomLottie = props => {
  let { index = 0 } = props
  let lottie = animationJSON[values[index]]
  const [left, setLeft] = useState(`${getRandomArbitrary(index, 100)}%`)
    , [top, setTop] = useState(`${getRandomArbitrary(index, 100)}%`)
    , [isVisible, setIsVisible] = useState(false)
    , speed = getRandomArbitrary(0, 3)

  let timeout = null

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = setTimeout(() => {
      setIsVisible(true)
    }, (index + 1) * 500);
    return () => { clearTimeout(timeout) }
  }, [])

  const onComplete = () => {
    setIsVisible(false)
    timeout = setTimeout(() => {
      setLeft(`${getRandomArbitrary(index, 100)}%`)
      setTop(`${getRandomArbitrary(index, 100)}%`)
      setIsVisible(true)
    }, 1000)
  }

  if (!isVisible) return null

  return <Lottie
    onComplete={onComplete}
    style={{
      opacity: isVisible ? 1 : 0,
      position: 'absolute',
      left,
      top
    }}
    className={`ramdom__lottie ${isVisible ? 'active' : ''}`}
    data-scroll data-scroll-speed={`${speed}`} data-scroll-position="left" data-scroll-direction="horizontal"
    animation={lottie}
  />
}

export default RandomLottie
