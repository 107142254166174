const locals = {
  'success_message_one': {
    'es': 'Gracias por contactarnos, ',
    'en': 'success_message_one'
  },
  'success_message_two': {
    'es': 'pronto nos comunicaremos contigo.',
    'en': 'success_message_two'
  },
  'form_name_label': {
    'es': 'Nombre',
    'en': 'Name'
  },
  'form_email_label': {
    'es': 'Email',
    'en': 'Email'
  },
  'form_phone_label': {
    'es': 'Teléfono',
    'en': 'Phone'
  },
  'form_info_label': {
    'es': 'Quiero informes sobre:',
    'en': 'form_info_label'
  },
  'form_btn_send': {
    'es': 'Enviar',
    'en': 'Send'
  },
  'general_error': {
    'es': '* algo salió mal.',
    'en': '* something went wrong.'
  },
}

export const getLocal = (key, lang) => {
  const text = (locals[key] && locals[key][lang]) || ''
  return text
}
